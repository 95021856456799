import React from "react";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  CardNavigation,
  HeroElement,
  Contact,
  ContactForm
} from "@fishrmn/fishrmn-components";
import emailCallback from "../utils/email.js";
import MessageBanner2 from "../components/MessageBanner/MessageBanner2";
import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <MessageBanner2 />
        <Layout>
          <Helmet>
            <title>Thai Amarin | Contact</title>
            <meta name="description" content="" />
          </Helmet>
          <PageLayout
            hero={
              <HeroElement
                header={"Contact"}
                tagline={""}
                images={[
                  "https://lh3.googleusercontent.com/p/AF1QipO2sgbiN7djfCQAzIrHnNLqd8ELGNPsN2GOJCUp=s1600-w1600-h1600"
                ]}
                showMap={false}
                ctas={[
                  {
                    text: "287 Centre St, Newton MA",
                    link:
                      "https://www.google.com/maps/search/?api=1&query=287 Centre St, Newton MA",
                    internal: false
                  },
                  {
                    text: "(617) 527-5255",
                    link: "tel:(617) 527-5255",
                    internal: false
                  }
                ]}
                ctaColor={"white"}
                ctaInverted={true}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={true}
                containerAs={"solid"}
                height={"100vh"}
                gutter={false}
              />
            }
          >
            <Grid stackable className="component-section-container">
              <Grid.Column width={16}>
                <Contact
                  phoneNumber={"(617) 527-5255"}
                  address={{
                    street: "287 Centre St",
                    city: "Newton",
                    state: "MA",
                    zip: "02458"
                  }}
                  as={"text"}
                  buttonColor={"secondary"}
                  header={"Contact Us"}
                  image={""}
                  showMap={true}
                />
              </Grid.Column>
              <Grid.Column width={16} textAlign="center">
                <h2>Hours</h2>
                Monday — Thursday
                <br />
                Lunch 11:30am — 3:00pm
                <br />
                Dinner 4:30pm — 9:30pm
                <br />
                <br />
                Friday — Saturday
                <br />
                Lunch 11:30am — 3:00pm
                <br />
                Dinner 4:30pm — 10:00pm
                <br />
                <br />
                Sunday
                <br />
                Dinner 4:00pm — 9:30pm
                <br />
                <br />
                (Opening hours can vary in each season. Please call ahead.)
              </Grid.Column>
            </Grid>
            <Grid
              stackable
              className="component-section-container"
              verticalAlign={"middle"}
              textAlign={"left"}
            >
              <Grid.Column width={16}>
                <ContactForm
                  buttonText={"Send"}
                  centeredHeaders={true}
                  className={""}
                  fluidButton={false}
                  header={"Contact Us"}
                  inline={false}
                  onSubmit={emailCallback}
                  showLabels={true}
                  subheader={""}
                  termsCopy={""}
                  withContainer={true}
                />
              </Grid.Column>
            </Grid>
          </PageLayout>
        </Layout>
      </React.Fragment>
    );
  }
}
