import React from "react";
import "semantic-ui-less/semantic.less";
import "./messageBanner.css";

export default class MessageBanner2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { showModal: false, bannerVersion: "1.0" };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  componentDidMount() {
    if (
      localStorage.getItem("fishermanMessageBannerShown") === null &&
      localStorage.getItem("fishermanMessageBannerVersion") === null
    ) {
      localStorage.setItem("fishermanMessageBannerShown", this.state.showModal);
      localStorage.setItem(
        "fishermanMessageBannerVersion",
        this.state.bannerVersion
      );
      this.setState({ ...this.state, showModal: true });
    } else if (
      localStorage.getItem("fishermanMessageBannerVersion") !==
      this.state.bannerVersion
    ) {
      localStorage.setItem("fishermanMessageBannerShown", false);
      this.setState({ ...this.state, showModal: true });
    } else if (localStorage.getItem("fishermanMessageBannerShown") === true) {
      this.setState({ ...this.state, showModal: true });
    }
  }

  closeModal(event) {
    event.stopPropagation();
    localStorage.setItem("fishermanMessageBannerShown", true);
    this.setState({ ...this.state, showModal: false });
  }

  openModal() {
    this.setState({ ...this.state, showModal: true });
  }

  render() {
    const { className = "" } = this.props;

    const messageBanner = `Restaurant Manager wanted for full service Thai restaurant.`;
    const messageString = `Requires minimum Bachelor’s in any business related field plus 24 months management related experience. Reply by resume only to SCBKK Enterprises, Inc. dba Thai Amarin, 287 Centre Street, Newton, MA 02458.`;
    return (
      <div className="message-banner-container" onClick={this.openModal}>
        <span className="message-banner-text">{messageBanner}</span>
        {!!this.state.showModal ? (
          <div className="message-banner-modal-container">
            <div className="message-banner-modal-overlay">
              <div className="message-banner-modal">
                <div className="message-banner-modal-header">
                  Important message
                </div>
                <div className="message-banner-modal-body">
                  <p>{messageString}</p>
                </div>
                <div className="message-banner-modal-footer">
                  <button type="button" onClick={this.closeModal}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
